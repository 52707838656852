// const BASE_URL = "http://localhost:3000"; //local
// export const BASE_URL = "http://192.168.29.157:4000"; // my server
// export const BASE_URL = "https://apebuzz.apeitnow.com"; //server
 export const BASE_URL = "https://v2.apebuzz.apeitnow.com"; //server

export const API = {
  GET_POLICIES: `/api/auth/adminAuth/get/policy/data/for/support`,
  GET_TERM_CONDITIONS: `/api/auth/adminAuth/get/terms/and/conditions/data/for/support`,
  GET_CHILD_SAFETY_STANDARD_POLICY: `/api/auth/adminAuth/get/childSafetyStandardsPolicy/data/for/support`,
};
