import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { API } from "../../utils/api.utils";
import Head from "../../components/head/head";

const ChildSafetyStandardsPolicy = () => {
  const [childSafetyStandardsPolicy, setChildSafetyStandardsPolicy] = useState(null);

  const getPrivacyPolcityData = async () => {
    try {
      const res = await axios.get(API.GET_CHILD_SAFETY_STANDARD_POLICY);
      setChildSafetyStandardsPolicy(res?.data?.policy?.policy || "");
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getPrivacyPolcityData();
    console.log("this is running");
  }, []);

  return (
    <>
      <Head
        title={"Child Safety Standards Policy"}
        description={"This is Child Safety Standards Policy "}
      />
      <Box maxWidth={"800px"} height={"100vh"} margin={"auto"} pb={4} pt={2}>
        <Box border={"2px solid black"} mb={4} width={"100%"}>
          {childSafetyStandardsPolicy ? parse(childSafetyStandardsPolicy) : ""}
        </Box>
      </Box>
    </>
  );
};

export default ChildSafetyStandardsPolicy;
